<template>
  <div id="cyberbank-integrations">
    <vs-table
      v-model="selected"
      :data="cyberbankApiCalls"
      @selected="handleSelected"
    >
      <template slot="thead">
        <vs-th>
          URL
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].url">
            {{ data[indextr].url }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import CyberbankService from '@/services/cyberbank.service'

export default {
  name: 'CyberbankIntegrations',
  data() {
    return {
      selected: null,
      apis: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['apiCalls']),
    cyberbankApiCalls() {
      return this.apiCalls.filter(ac => ac.type && ac.type === 'cyberbank')
    }
  },
  methods: {
    closeIntegrationsPopup() {
      this.selected = []
      this.$emit('close', true)
    },
    // async updateCyberbankIntegrations() {
    //   // this.$vs.loading()

    //   this.$vs.notify({
    //     title: `Selected ${this.selected.name}`,
    //     text: `id ${this.selected.id}`
    //   })

    //   // this.$vs.loading.close()
    //   this.closeIntegrationsPopup()
    // },
    handleSelected(tr) {
      this.selected = tr
      this.$emit('onChange', [tr._id])
    }
  }
}
</script>
